<template>
<div class="dynamic-landing">
  
    <div class="statement">
        <p>Wilt u een aangifte doen? U kan ons bereiken op 056 77 39 85 of via het <a href="#contact">het contact formulier</a></p>
    </div>
        <p class="description">{{description}}</p>
    <img class="landing-img" v-bind:src="source">
</div>
</template>

<script>
import { db } from '../db'
var doc;



export default {
    data() {
    return {
      description: "",
      source:""
    };
  },
  
  created(){
    db.collection('landing').get().then(querySnapshot => {
        const docs = querySnapshot.docs.map(doc => doc.data())
        doc = docs[Math.floor(Math.random() * docs.length)];
        this.description = doc.description
        this.source = doc.source
  })
  },
}
</script>

<style>
.dynamic-landing{
    width:100%;
    height: 500px;
    display: flex;
}

.statement p{
  font-family: regular;
  padding-top:15px;
  padding-bottom: 15px;
  margin-left:150px;
}
@media screen and (max-width: 575px) {
.dynamic-landing{
    width:100%;
    height: 325px;
    display: flex;
}

.statement p{
  margin-left:5px;
  margin-right: 5px;
}
}
</style>

<style scoped>
@font-face {
  font-family: regular;
  src: url("../assets/fonts/Ciutadella-Regular.otf");
}



.statement p a{
  color:white
}

.statement p a:visited{
  color:white
}


.statement{
  text-align: left;
  position:absolute;
  z-index: 1;
    background-color: #002d7d;
    width: 100%;
    color: white;
}

.description{
 position: absolute;
 display: block;
 float:left;
 margin-left:150px;
}

.landing-img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>