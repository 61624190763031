<template>
  <div id="insurance">
                <h2>Onze Verzekeringen</h2>
    <h3>Vind uw verzekering op maat</h3>

<div class="products">

 <div class="inline" v-bind:key="doc" v-for="doc in docs">
    <a v-bind:href="doc.link" target="blank">
  <div class="product">
     <span class="icon" v-html="doc.icon" > {{doc.icon}}</span>
       
       <p class="type">{{doc.type}}</p>
  </div>
    </a>
 </div>
  </div>
  </div>
</template>

<script>
import { db } from '../db'

export default {
    data() {
    return {
        docs:[]
    };
  },
  
  created(){
    db.collection('insurance').get().then(querySnapshot => {
         querySnapshot.docs.map(doc => this.docs.push(doc.data()))
  })
  }
}
</script>


<style>

.products{
    text-align: left;
    margin-left:150px;
    margin-bottom: 30px;
}

@media screen and (max-width: 1120px) {
.products{
    text-align: center;
    margin-left:0px;
}
}
</style>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.inline{
    display: inline;
}
#insurance{
        scroll-margin-top: 6em;

    text-align: left;
                border-bottom: #b9b9b9 solid 1px;

}


.product{
    font-family: semibold;
    color:#002d7d;
    text-align: center;
    display: inline-block;
    height:200px;
    width:200px;
    background-color: #f7f7f7;
    border: #b9b9b9 solid 1px;
    margin-right:30px;
    margin-top:30px;
}


.product:hover{
    cursor: pointer;
        background-color: white;

        color:#feaa3f;


}

.icon{
    display: block;
    margin-top:75px;
    font-size: 2.4em;
}

.type{
 font-size:1.2em;
}


</style>
