<template>
<div id="about">
            <h2>Over ons</h2>
    <h3>Maak kennis met ons team dat voor u klaarstaat</h3>
    <div class="content">

    <div class="employee" v-bind:key="doc" v-for="(doc) in docs">
                <div class="avatar">
        <img v-bind:src="doc.avatar" />
        </div>
        <div class="card">
            <p class="title">{{doc.title}}</p>
            <p class="name">{{doc.name}}</p>
            <p class= "description">{{doc.description}}</p>
            
            <p class="email"><a v-bind:href="'mailto:'+doc.email">{{doc.email}}</a></p>
        </div>
    </div>

    </div>
</div>
</template>

<script>
import { db } from '../db'

export default {
    data() {
    return {
        docs:[]
    };
  },
  
  created(){
    db.collection('employee').get().then(querySnapshot => {
         querySnapshot.docs.map(doc => this.docs.push(doc.data()))
  })
  }
}
</script>

<style>
.employee{
    align-self: center;
    display: inline-table;
      table-layout: fixed;

   margin-top:30px;
        width:400px;

        margin-left:5%;
        margin-right:5%;

}

h2{
    font-size:2.0em;
    margin-top:30px;
        font-family: semibold;
        margin-bottom:15px;
        color:#002d7d;
        margin-left: 150px;
}

h3{
        font-size:1.4em;
        font-family: regular;
                color:#002d7d;
                        margin-left: 150px;

}

@media screen and (max-width: 575px) {

h2{
  font-size: 1.4em;;
}


h3{
  font-size: 1em;
}

h2,h3{
  max-width: 350px;
  margin-left:5px;
}


.employee{
    width:auto
}

}

@media screen and (max-width: 1120px) {
.employee{
    display: block;
    margin:auto;
        margin-top:30px;

}
    
}

</style>



<style scoped>

@font-face {
  font-family: semibold;
  src: url("../assets/fonts/Ciutadella-SemiBold.otf");
}

@font-face {
  font-family: regular;
  src: url("../assets/fonts/Ciutadella-Regular.otf");
}


.content{
    text-align: left;
    margin:auto;
    max-width: 1000px;
    margin-bottom: 30px;
}


#about{
    scroll-margin-top: 6em;

    text-align: left;
    margin-top:0px;
height: auto;
background-color: #f7f7f7;
            border-top: #b9b9b9 solid 1px;
            border-bottom: #b9b9b9 solid 1px;

}

.card{
    text-align: left;
    border-top: solid #feaa3f 12px;
    height:340px;
    width:auto;
    background-color: white;
      z-index: -1;

    margin-top:-85px;
        border-bottom: solid 10px #e2e2e2;


}


p{
    font-family: regular;
    margin-left: 25px;
        margin-right: 25px;
    font-size:1.2em;
      line-height: 150%;

    color:#757575;
}

.card p:first-child{
    margin-top:95px;
}

.avatar img {
  display: block;
  border-radius: 50%;
  border: solid #002d7d 8px;
  margin: auto;
  z-index: 1;
  max-width: 200px;
  max-height: 200px;
  min-height: 200px;
  min-width: 200px;
}


.title{
    font-family: semibold;
    color:#feaa3f;
    text-transform: uppercase;
          letter-spacing: 0.1em;

}

.name{
        font-family: semibold;
                  letter-spacing: 0.1em;
                  margin-bottom: 25px;
}

.email{
    margin-top:25px;
}


</style>