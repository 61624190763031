<template>
  <div id="contact">
    <h2>Contact</h2>
    <h3>
      Afspraak maken? Graag meer informatie? <br /> Neem gerust contact op met ons
      kantoor
    </h3>
    <form @submit.prevent="sendEmail">
      <label for="vnaam">Voornaam<span class="required">*</span></label
      ><br />
      <input type="text" id="vnaam" name="vnaam" value="" required/><br /><br />

      <label for="naam">Naam<span class="required">*</span></label
      ><br />
      <input type="text" id="naam" name="naam" value=""  required/><br /><br />

      <label for="email">E-mail<span class="required">*</span></label
      ><br />
      <input type="email" id="email" name="email" value=""  required/><br /><br />

      <label for="gsm">GSM</label><br />
      <input type="tel" id="gsm" name="gsm" /><br /><br />

      <label for="bericht">Bericht</label><br />
      <textarea id="bericht" name="bericht" rows="8" cols="42"> </textarea>
      <input type="submit" value="Verzenden" />
    </form>
    <div class="flex">
      <div class="open">
        <p class="title">Openingsuren</p>
        <ul>
            <li v-bind:key="doc" v-for="doc in docs"><span class="day">{{doc.day}}</span> <span class="hours">{{doc.morning}} - {{doc.afternoon}}</span></li>
        </ul>
      </div>

      <div class="map">
        <iframe
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=Verzekeringen%20VANDEBUERIE&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>
    </div>



  </div>
</template>

<script>
import emailjs from 'emailjs-com';

import { db } from '../db'

export default {

  methods: {
    sendEmail: (e) => {
      emailjs.sendForm('service_u1nft07', 'template_f6ayagl', e.target,"bYtDLRAZMYS7dt3yN")
        .then(() => {
            location.reload(true);
        });
    }
  },

    data() {
    return {
        docs:[]
    };
  },
  
  created(){
    db.collection('open').get().then(querySnapshot => {
         querySnapshot.docs.map(doc => this.docs.push(doc.data()))
  })
  }
}
</script>

<style>

input[type="submit"] {
  display: block;
  color: white;
  font-family: semibold;
  background-color: #002d7d;
  border: none;
  font-size: 1.6em;
  height: 40px;
  width: 400px;
  margin-top: 15px;
  letter-spacing: 0.1em;
  padding-top:3px;
}

input[type="submit"]:hover{
  background-color: #feaa3f;
  cursor: pointer;
}

.flex {
  display: flex;
}

.open {
    text-align: center;
  border-top: solid #feaa3f 12px;
  height: 450px;
  width: 400px;
  background-color: white;
  border-bottom: solid 10px #e2e2e2;
  margin-left: 150px;
  vertical-align: top;
  margin-right: 30px;
}

.map {
  display: flex;
  flex-direction: column;
  text-align: left;
  border-top: solid #feaa3f 12px;
  height: 450px;
  min-width: 400px;
  border-bottom: solid 10px #e2e2e2;
  vertical-align: top;
  flex-grow: 100;
  margin-right: 150px;
}

iframe {
  border: none;
  height: 450px;
  flex-grow: 1;
}


h2 {
  font-size: 2em;
  margin-top: 30px;
  font-family: semibold;
  margin-bottom: 15px;
  color: #002d7d;
  margin-left: 150px;
}

h3 {
  font-size: 1.4em;
  font-family: regular;
  color: #002d7d;
  margin-left: 150px;
}

form {
  margin-top: 30px;
  margin-left: 150px;
  margin-bottom: 30px;
}

@media screen and (max-width: 1120px) {
  .flex{
    display: block;
  }

.open {
  min-width: 400px;
display: block;
}


.map{
  display: block;
    max-width: 400px;
    margin-top:50px;
    margin-left:150px;

}

iframe {
  width:400px;
}

}

@media screen and (max-width: 575px) {

h2{
  font-size: 1.4em;;
}


h3{
  font-size: 1em;
}

h2,h3{
  max-width: 350px;
  margin-left:5px;
}

form {
  margin-top: 50px;
  margin-left: 5px;
  margin-bottom: 100px;
}

.open {
  margin-left:5px;
    min-width: 0px;
    width: 350px;
}


.map{
    margin-left:5px;
    min-width: 0px;
    width: 350px;
}

iframe{
  max-width: 350px;
}

input[type="submit"] {
  width: 350px;
}

}


</style>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
  font-family: semibold;
  src: url("../assets/fonts/Ciutadella-SemiBold.otf");
}

@font-face {
  font-family: regular;
  src: url("../assets/fonts/Ciutadella-Regular.otf");
}



.open li{
font-family: regular;
  margin-top:35px;
  text-align: left;
margin-left:25px;
}

.day{
  display: inline-block;
  width:150px;
}

.hours{
  text-align: right;
}

.title{
      font-family: semibold;
    color: #feaa3f;
      letter-spacing: 0.1em;
      font-size: 1.2em;
    text-transform: uppercase;
    margin-top:15px;
}



#contact {
    scroll-margin-top: 6em;
  text-align: left;
}

label {
  font-size: 1.2em;
  font-family: semibold;
  color: #002d7d;
  letter-spacing: 0.1em;
}

.required {
  color: #ff9e16;
}


</style>
